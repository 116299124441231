<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :loading="loading"
    class="h-full w-full flex flex-col justify-between compact"
  >
    <p
      v-if="this.formErrors !== ''"
      class="text-tiny text-error mb-0 px-4 py-2"
    >
      {{ formErrors }}
    </p>
    <div class="h-full max-h-0 overflow-x-scroll pt-4 px-4">
      <ToznyInput
        class="mb-4 mr-10"
        label="Record Type"
        id="record-type"
        name="record-type"
        :disabled="recordTypeLocked"
        v-model="type"
      />
      <ToznyKeyValue
        title="Meta (plain text)"
        id="meta"
        name="meta"
        v-model="meta"
        class="mb-4"
      />
      <ToznyKeyValue
        title="Data (encrypted)"
        id="data"
        name="data"
        v-model="data"
      />
    </div>
    <div class="flex-shrink-0 flex-grow-0 pt-2 mb-4 px-4">
      <div class="flex flex-row-reverse items-center">
        <ToznyButton
          id="new-record-submit"
          data-auto-id="new-record-submit"
          class="m-0"
          :buttonText="submitText ? submitText : 'Submit'"
          :loading="loading"
        />
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mr-4"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyKeyValue from '@/Common/ToznyKeyValue'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'EditForm',
  components: {
    ToznyForm,
    ToznyInput,
    ToznyKeyValue,
    ToznyButton,
  },
  props: {
    record: {
      type: Object,
      default: () => ({
        meta: {
          type: '',
        },
      }),
    },
    loading: Boolean,
    error: String,
    submitText: String,
  },
  data() {
    return {
      validationErrors: [],
      type: (() => {
        return Object.keys(this.record).length !== 0 &&
          typeof this.record.meta === 'object'
          ? this.record.meta.type
          : ''
      })(),
      meta:
        Object.keys(this.record).length !== 0 &&
        typeof this.record.meta === 'object' &&
        typeof this.record.meta.plain === 'object'
          ? this.record.meta.plain
          : {},
      data:
        Object.keys(this.record).length !== 0 &&
        typeof this.record.data === 'object'
          ? this.record.data
          : {},
    }
  },
  computed: {
    formErrors() {
      const errors = [...this.validationErrors]
      if (this.error) {
        errors.push(this.error)
      }
      return errors.join(', ')
    },
    recordTypeLocked() {
      return this.type !== '' && this.type === this.record.meta.type
    },
  },
  methods: {
    handleSubmit() {
      this.validationErrors = []
      const sanitizedType = this.type.trim()
      const errors = []
      if (sanitizedType === '') {
        errors.push('record type can not be empty')
      }
      if (Object.keys(this.data).length === 0) {
        errors.push('data must have at least one valid entry in it')
      }
      if (errors.length > 0) {
        this.validationErrors = errors
        return
      }
      this.$emit('submit', {
        record: this.record,
        type: this.type,
        meta: this.meta,
        data: this.data,
      })
    },
  },
}
</script>

<style>
.compact input {
  padding: 0.33em 0.25em;
  height: auto;
}
.compact fieldset div {
  margin-bottom: 0.25em;
}
.compact label,
.compact legend {
  font-size: 12px;
  margin-bottom: 0.5em;
}

.compact .float-label label {
  font-size: 16px;
}
</style>
