<template>
  <fieldset>
    <legend class="mb-2 font-display1 font-light">{{ title }}</legend>
    <div
      v-for="(singleValue, i) in values"
      v-bind:key="singleValue.uid"
      class="flex items-center w-full mb-4"
    >
      <ToznyInput
        name="key"
        v-model="values[i].key"
        :error="hasError(i)"
        float-label
        hide-float
        label="Key"
        id="key"
        class="flex-grow mr-4"
      />
      <ToznyInput
        name="value"
        v-model="values[i].value"
        float-label
        hide-float
        label="Value"
        id="value"
        class="flex-grow mr-4 float-hide"
      />
      <a
        herf="#!"
        @click.prevent="removeItem(i)"
        class="flex cursor-pointer no-underline text-gray-dark"
        :title="`remove '${singleValue.key}'`"
      >
        <span class="sr-only"
          >remove "{{ singleValue.key }}" from the data set</span
        >
        <i class="material-icons-outlined md-24 micon">remove</i>
      </a>
    </div>
    <a
      herf="#!"
      @click.prevent="addItem"
      class="inline-block h-6 cursor-pointer no-underline text-gray-dark"
      :title="`add key value pair to ${title}`"
    >
      <span class="sr-only">add key value pair to {{ title }}</span>
      <i class="material-icons-outlined md-24 micon">add</i>
    </a>
  </fieldset>
</template>

<script>
import ToznyInput from '@/Common/ToznyInput'

let uid = 1
export default {
  name: 'ToznyKeyValue',
  components: {
    ToznyInput,
  },
  props: {
    title: String,
    value: {
      type: Object,
      default: () => {},
      validator: (items) =>
        Object.keys(items).every(
          (k) => typeof k === 'string' && typeof items[k] === 'string'
        ),
    },
  },
  data() {
    return {
      values: this.toArray(this.value),
    }
  },
  computed: {
    errors() {
      const foundErrors = []
      const lookup = {}
      for (let i = 0; i < this.values.length; i++) {
        if (lookup[this.values[i].key]) {
          foundErrors.push({ index: i })
        } else {
          lookup[this.values[i].key] = true
        }
      }
      return foundErrors
    },
  },
  methods: {
    toArray(obj) {
      const arr = []
      for (let key in obj) {
        arr.push({ key, value: obj[key], uid: uid++ })
      }
      return arr.length === 0 ? [{ key: '', value: '', uid: uid++ }] : arr
    },
    addItem() {
      this.values = [...this.values, { key: '', value: '', uid: uid++ }]
    },
    removeItem(i) {
      const removed = [...this.values]
      removed.splice(i, 1)
      this.values = removed
    },
    hasError(i) {
      for (let err of this.errors) {
        if (err.index === i) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    values: {
      deep: true,
      handler: function (values) {
        const obj = {}
        for (let val of values) {
          if (val.key !== '' && obj[val.key] === undefined) {
            obj[val.key] = val.value
          }
        }
        this.$emit('input', obj)
      },
    },
  },
}
</script>

<style></style>
